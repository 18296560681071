<template>
  <div
    v-if="getElementData"
    :class="title.toLowerCase()">
    <span
      v-if="isShowTitle"
      class="title">
      {{ title }}:
    </span>
    <span
      class="information">
      {{ information }}
    </span>
  </div>
</template>
<script>
import productHeaders from '@/constants/productHeaders';
export default {
  name: 'ElementContainer',
  props: {
    element: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    getElementData() {
      const { element, item } = this;
      const { DESCRIPTION, TYPE_DESIGNATION } = productHeaders;
      if (item[element] && item[element] !== '\b' && item[element] !== ' ') {
        return {
          title: element,
          component: item?.[element],
        };
      }
      return {
        title: element,
        component: [DESCRIPTION, TYPE_DESIGNATION].includes(element) ? '' : '-',
      };
    },
    isShowTitle() {
      const { title = null } = this.getElementData;
      return title !== productHeaders.TYPE_DESIGNATION;
    },
    information() {
      if (this.getElementData.component?.length > 80) {
        return `${this.getElementData.component.substr(0, 80)}...`;
      }
      return Array.isArray(this.getElementData.component) ? this.getElementData.component.join(', ') : this.getElementData.component;
    },
    title() {
      return this.getElementData.title;
    },
  },
};
</script>
